import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { getRegistrationMaterial, setRegisterSteps } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { RegisterStep6Schema } from "../../util/validationSchema";
import { TeacherIcon, GraduteIcon } from "../../util/iconStyle";
import { useFormik } from "formik";
import Select from "react-select";
import { Store } from "../../redux/Actions";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { formatDate, getFileName } from "../../util/utils";
import { ContentState, convertFromHTML, EditorState } from "draft-js";

const RegisterStep6 = ({ onBack }: any) => {
  const dispatch = useDispatch();
  const local = useSelector((state: Store) => state.userDataReducer.local);
  const classChoice = useSelector(
    (state: Store) => state.registerReducer.classChoice,
  ) as Array<Object>;
  const repeaterClass = useSelector(
    (state: Store) => state.registerReducer.repeaterClass,
  ) as Array<Object>;
  const [selectedJoinClass, setSelectedJoinClass] = useState<any>(null);
  const [selectedRepeaterClass, setSelectedRepeaterClass] = useState<any>(null);
  const [selectedClassInfo, setSelectedClassInfo] = useState<any>({
    name: "",
    price: 0,
    closeApplicationDate: "",
    advertisement: [],
  });

  const users = useSelector((state: Store) => state.userDataReducer.userObject);

  const intl = useIntl();

  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
    dispatch(getRegistrationMaterial(false, local));
  }, [local]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      classChoice: users.classChoice || 0,
      repeaterClass: users.repeaterClass || 1,
      repeater: users.repeater || false,
      price: users.price || 0,
    },
    validationSchema: RegisterStep6Schema,
    onSubmit: (values) => {
      let allRegister: any = [];
      let className: any = {};
      classChoice.map((i: any) => {
        if (i.id == values.classChoice) {
          className = i;
        }
      });

      if (!values.repeater) {
        allRegister = {
          classChoice: values.classChoice,
          repeater: values.repeater,
          price: values.price,
          className: className.name,
        };
        dispatch(setRegisterSteps(5, allRegister));
      } else {
        allRegister = {
          ...values,
          className: className.name,
        };
        dispatch(setRegisterSteps(5, allRegister));
      }
    },
  });

  useEffect(() => {
    if (users.repeater) {
      setActiveCard(1);
      classChoice.map((c: any) => {
        if (users.classChoice === c.id) {
          setSelectedClassInfo(c);
          setSelectedJoinClass(c);
        }
      });
      repeaterClass.map((c: any) => {
        if (users.repeaterClass === c.id) {
          setSelectedRepeaterClass(c);
        }
      });
    } else {
      setActiveCard(0);
      classChoice.map((c: any) => {
        if (users.classChoice === c.id) {
          setSelectedClassInfo(c);
          setSelectedJoinClass(c);
          formik.setFieldValue("repeaterClass", 1);
        }
      });
    }
  }, [users]);

  const onCardSelection = (index: number, repeater: boolean, price: number) => {
    setActiveCard(index);
    if (index === 0) {
      formik.setFieldValue("repeaterClass", 1);
    } else {
      formik.setFieldValue("repeaterClass", -1);
    }
    formik.setFieldValue("repeater", repeater);
    formik.setFieldValue("price", price);
  };

  useEffect(() => {
    if (activeCard === 0) {
      // selectedClassInfo({})
    }
  }, [selectedJoinClass]);

  const { touched, errors } = formik;

  const getEditorState = (data) => {
    const blocksFromHTML = convertFromHTML(data || "Test");
    const estate = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      ),
    );
    return estate;
  };
  return (
    <>
      <div className="mb-4">
        <div className="d-flex flex-wrap justify-content-between border-bottom pb-4 mb-4">
          <h1 className="h5 font-weight-600 m-0">
            {" "}
            <FormattedMessage
              id="register.setp6"
              defaultMessage="Step 6: Course Registration"
            />{" "}
          </h1>
          <button
            type="button"
            onClick={onBack}
            className="btn btn-primary btn btn-primary"
          >
            <BsChevronLeft />{" "}
            <FormattedMessage
              id="register.step6.Back"
              defaultMessage="Back"
            />{" "}
          </button>
        </div>
        <Card
          onClick={() => {
            onCardSelection(0, false, 36800);
          }}
          className={
            activeCard === 0 ? "card-active bg-light" : "card-inActive pointer"
          }
        >
          <Card.Body>
            <Row>
              <Col sm={4} md={3} lg={2} className="align-middle">
                <div className="mx-auto h-50 w-50" style={{ fill: "#68439a" }}>
                  <TeacherIcon />
                </div>
              </Col>
              <Col sm={8} md={9} lg={10}>
                <h5>
                  <FormattedMessage
                    id="attendence.Class"
                    defaultMessage="Class"
                  />
                  : <b>{selectedClassInfo.name} </b>{" "}
                </h5>
                {/* <label><FormattedMessage id='register.step6.Course.label' defaultMessage="The 27th FF Automated Program Trading Course " /> </label> */}
                <p>
                  <FormattedMessage
                    id="register.step6.Paragraph"
                    defaultMessage="For investors who are interested in learning Forex program trading we provide an AI all-round automatic program trading course which teaches how to use the automatic program trading system for foreign exchange trading Clearly understand the basics of automated trading
                                    The comprehensive course will teach how to analyze the pros and cons of automatic program trading use past transaction data for testing and use real warehouses for actual combat testing to improve the profit ratio."
                  />
                </p>
                {/* <p><b> <FormattedMessage id='register.step6.Register.deadline' defaultMessage=" ***The registration deadline will be ." />{formatDate(selectedClassInfo?.closeApplicationDate, 'DD-MM-YYYY')} </b> </p> */}
                {local === "zh" ? (
                  <>
                    {" "}
                    <p className="m-0">
                      <b className="mr-2">
                        {/* {selectedClassInfo?.closeApplicationDate ?
                                            <FormattedDate value={formatDate(selectedClassInfo?.closeApplicationDate, 'DD MMM YYYY')}
                                                day="numeric"
                                                month="long"
                                                year="numeric" />
                                            : '--'} */}
                      </b>
                      <FormattedMessage
                        id="register.step6.Register.closedate"
                        values={{
                          date: (
                            <>
                              {selectedClassInfo?.closeApplicationDate ? (
                                <FormattedDate
                                  value={formatDate(
                                    selectedClassInfo?.closeApplicationDate,
                                    "DD MMM YYYY",
                                  )}
                                  day="numeric"
                                  month="long"
                                  year="numeric"
                                />
                              ) : (
                                "--"
                              )}
                            </>
                          ),
                        }}
                        defaultMessage="***Registration will close before "
                      />
                    </p>
                  </>
                ) : (
                  <p className="m-0">
                    <b>
                      <FormattedMessage
                        id="register.step6.Register.closedate"
                        defaultMessage="***Registration will close before "
                      />
                      {selectedClassInfo?.closeApplicationDate ? (
                        <FormattedDate
                          value={formatDate(
                            selectedClassInfo?.closeApplicationDate,
                            "DD MMM YYYY",
                          )}
                          day="numeric"
                          month="long"
                          year="numeric"
                        />
                      ) : (
                        "--"
                      )}
                    </b>
                  </p>
                )}
                <p className="m-0">
                  <b>
                    {" "}
                    HKD$ {selectedClassInfo.price}{" "}
                    <FormattedMessage
                      id="register.step6.Payment"
                      defaultMessage=" /One-Time Payment "
                    />
                  </b>{" "}
                </p>
              </Col>
            </Row>
            {/* {selectedClassInfo?.advertisement.length > 0 &&
              selectedClassInfo?.advertisement?.map((addvertisement) => {
                const details = getEditorState(addvertisement.details);
                return (
                  <>
                    <Row className="mt-3">
                      <Col sm={4} md={3} lg={2}>&nbsp;</Col>
                      <Col sm={8} md={9} lg={10}>
                        <div className="mb-3 pb-3 border-bottom student-class-reg">
                          <div className="mb-3"> Advertisement: </div>
                          <div className="d-flex">
                            <img className="student-class-ad-image mr-3" src={addvertisement?.advertisementImage} />
                            <div className="flex-fill">
                              <div className="mb-3">
                                <label className="text-muted d-block mb-1">Title</label>
                                {addvertisement?.name}
                              </div>
                              <div className="mb-3">
                                <label className="text-muted d-block mb-1">Description</label>
                                {" "}
                                {addvertisement.details && (
                                  // const state=getEditorState(addvertisement.details)
                                  <EditorContainer
                                    onEditorStateChange={() => {
                                      //change
                                    }}
                                    editorState={details}
                                    toolbar={false}
                                    editable={false}
                                    height={""}
                                    overflowY={""}
                                    border={""} // toolbar={editorConfig.toolbar}
                                  // editable={editorConfig.editable}
                                  // height={editorConfig.height}
                                  // overflowY={editorConfig.overflowY}
                                  // border={editorConfig.border}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              })} */}
          </Card.Body>
        </Card>
        {activeCard === 0 ? (
          <>
            <FormattedMessage
              id="register.step6.Select"
              defaultMessage="Select Class"
            />
            <Select
              classNamePrefix="react-select-dropdown"
              value={selectedJoinClass}
              onBlur={() => formik.setFieldTouched("classChoice", true)}
              onChange={(selectedOption: any) => {
                setSelectedClassInfo({
                  name: selectedOption.name,
                  price: selectedOption.sellingPrice,
                  closeApplicationDate: selectedOption.closeApplicationDate,
                  advertisement: selectedOption.Advertisement,
                });
                formik.setFieldValue("classChoice", selectedOption.id);
                setSelectedJoinClass(selectedOption);
              }}
              options={classChoice}
              name="classChoice"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={intl.formatMessage({
                id: "register.step6.SelectClass",
                defaultMessage: "Select Class",
              })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.classChoice && touched.classChoice && (
              <div className="error">
                <FormattedMessage
                  id={errors.classChoice.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </>
        ) : null}
        <br />
        <Card
          onClick={() => {
            onCardSelection(1, true, 2000);
          }}
          className={
            activeCard === 1 ? "card-active bg-light" : "card-inActive pointer"
          }
        >
          <Card.Body>
            <Row className="form-row">
              <Col sm={4} md={3} lg={2} className="align-middle">
                <div className="mx-auto h-50 w-50" style={{ fill: "#68439a" }}>
                  <GraduteIcon />
                </div>
              </Col>
              <Col sm={8} md={9} lg={10}>
                <h5>
                  <b>
                    {" "}
                    <FormattedMessage
                      id="register.step6.Student.register"
                      defaultMessage="Former Student Register"
                    />{" "}
                  </b>{" "}
                </h5>
                {/* <label>The 27th FF Automated Program Trading Course</label> */}
                <p>
                  <FormattedMessage
                    id="register.step6.Paragraph.student"
                    defaultMessage="If you are a former student of ForexForest and have not established an account with Gems3 system, please register through this option. Before setting up an account, you should contact the Customer Service Officer for the relevant registration procedures. If you are a former student of ForexForest, and have not established an account in Gems3 system, please register through this option. Before creating an account, you should first contact the customer service officer to understand the relevant registration procedures."
                  />{" "}
                </p>
                <p className="m-0">
                  <b>
                    {" "}
                    <FormattedMessage
                      id="register.step6.OnetimePayment"
                      defaultMessage="HKD$2000/One-Time Payment "
                    />{" "}
                  </b>{" "}
                </p>
                {/* <p><b> ***Registration will close before 2022-02-28. HKD$ 36800 /One-Time Payment</b> </p> */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {activeCard === 1 ? (
          <>
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="register.step6.Select.Class"
                  defaultMessage="Please select the course you want to repeat"
                />{" "}
              </label>
              <Select
                classNamePrefix="react-select-dropdown"
                value={selectedJoinClass}
                onBlur={() => formik.setFieldTouched("classChoice", true)}
                onChange={(selectedOption: any) => {
                  formik.setFieldValue("classChoice", selectedOption.id);
                  setSelectedJoinClass(selectedOption);
                }}
                options={classChoice}
                name="classChoice"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={intl.formatMessage({
                  id: "register.step6.SelectClass",
                  defaultMessage: "Select Class",
                })}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.classChoice && touched.classChoice && (
                <div className="error">
                  <FormattedMessage
                    id={errors.classChoice.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </div>
            {/* <br /> */}
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="register.step6.Select.Classjoin"
                  defaultMessage="Please select the course you want to repeat"
                />{" "}
              </label>
              <Select
                classNamePrefix="react-select-dropdown"
                value={selectedRepeaterClass}
                onChange={(selectedOption: any) => {
                  formik.setFieldValue("repeaterClass", selectedOption.id);
                  setSelectedRepeaterClass(selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("repeaterClass", true)}
                options={repeaterClass}
                name="repeaterClass"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={intl.formatMessage({
                  id: "register.step6.SelectClass",
                  defaultMessage: "Select Class",
                })}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.repeaterClass && touched.repeaterClass && (
                <div className="error">
                  <FormattedMessage
                    id={errors.repeaterClass.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
              <>
                {selectedRepeaterClass?.terms_condtion_path && (
                  <Row className="mt-3">
                    <Col md={4} lg={3} sm={6}>
                      <FormattedMessage
                        id="table.head.terms_condtion_path"
                        defaultMessage="Terms & Conditions"
                      />
                      :{" "}
                    </Col>
                    <Col>
                      {selectedRepeaterClass?.terms_condtion_path ? (
                        <div
                          className="details-td text-primary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(
                              selectedRepeaterClass?.terms_condtion_path,
                              "_blank",
                            );
                          }}
                        >
                          {getFileName(
                            selectedRepeaterClass?.terms_condtion_path,
                          )}
                        </div>
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                )}
              </>
            </div>
          </>
        ) : (
          ""
        )}
        <Row className="form-row">
          <Col>
            <p className="mb-2">
              <b>
                {" "}
                <FormattedMessage
                  id="register.step6.Notes"
                  defaultMessage="Notes:"
                />{" "}
              </b>
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step6.Notes1"
                defaultMessage="1. Before enrolling in the course, please VISIT the ForexForest website or CONTACT the customer service officer for course information."
              />{" "}
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step6.Notes2"
                defaultMessage="2. If you have any questions, please contact the customer service officer for assistance."
              />{" "}
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step6.Notes3"
                defaultMessage="3. You can contact the customer service officer via WhatsApp."
              />{" "}
            </p>
          </Col>
        </Row>
      </div>
      <div className="text-right mt-auto">
        <Button
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          <FormattedMessage
            id="register.step6.Btn"
            defaultMessage="Confirm and Next"
          />{" "}
          <BsChevronRight />{" "}
        </Button>
      </div>
    </>
  );
};

export default RegisterStep6;
