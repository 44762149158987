import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../redux";
import { getQR } from "../redux/action";
import { Store } from "../redux/Actions";
import QRCode from "react-qr-code";
import Announcements from "./Annoucements";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import NewGift from "../Components/Dashboard/NewGift";
import NewClass from "../Components/Dashboard/NewClass";
import { getallVpsMachinesList } from "../redux/action/vpsActions";
import GemsAdvertisementsMarquee from "../Components/Dashboard/GemsAdvertisementsMarquee/GemsAdvertisementsMarquee";

const Dashboard = () => {
  const useInfo = useSelector((state: Store) => state.userDataReducer.myInfo);

  const allVpsMachinesList = useSelector(
    (state: Store) => state.vpsReducer.allVpsMachinesList,
  );

  const [QR, setQR] = useState("");

  useEffect(() => {
    dispatch(getQR());
    dispatch(getallVpsMachinesList());
  }, []);

  useEffect(() => {
    const qr = { QR: useInfo.QR, id: useInfo.id };
    setQR(JSON.stringify(qr));
  }, [useInfo]);

  return (
    <>
      <div className="content-wrapper">
        <div className="content">
          <header className="page-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h1>
                  <FormattedMessage
                    id="dashboard"
                    defaultMessage="Dashboard"
                  />{" "}
                </h1>
              </div>
            </div>
          </header>
          <section className="page-content container-fluid client-dashboard">
            <Row className="g-5" xs={1} sm={1} md={1} lg={2} xl={3}>
              <Col className="mb-4">
                <Card className="equal-height mb-0">
                  <Card.Header style={{ position: "relative" }}>
                    <h5 style={{ position: "absolute" }}>
                      <FormattedMessage
                        id="dashboard.QRcode"
                        defaultMessage="QR Code"
                      />
                    </h5>
                  </Card.Header>
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    {Object.keys(useInfo).length > 0 && QR && (
                      <div style={{ background: "white", padding: "20px" }}>
                        <div className="qr-code-image" style={{}}>
                          <QRCode
                            style={{ height: "auto" }}
                            viewBox={`0 0 256 256`}
                            value={QR}
                          />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4">
                <div
                  className="d-flex flex-column justify-content-between"
                  style={{
                    gap: "0.5rem",
                  }}
                >
                  <div>
                    <Card className="equal-height">
                      <Card.Body>
                        <h2>{allVpsMachinesList?.length || 0}</h2>
                        <small></small>
                        <h4>
                          <FormattedMessage
                            id="RunningVps"
                            defaultMessage="Running Vps"
                          />
                        </h4>
                      </Card.Body>
                    </Card>
                  </div>
                  <div>
                    <Card className="equal-height">
                      <Card.Body>
                        <h2>
                          {useInfo.demoAccountCreated}/{useInfo.demoLimit}
                        </h2>
                        <small>
                          <FormattedMessage
                            id="accounts.Smalltag"
                            defaultMessage="(No. of Temporary Accounts: 0)"
                          />
                        </small>
                        <h4>
                          <FormattedMessage
                            id="accounts.Demo"
                            defaultMessage="Demo Account"
                          />
                        </h4>
                      </Card.Body>
                    </Card>
                  </div>
                  <div>
                    <Card className="equal-height mb-0">
                      <Card.Body>
                        <h2>
                          {useInfo.liveAccountCreated}/{useInfo.liveLimit}
                        </h2>
                        <small>
                          <FormattedMessage
                            id="accounts.Smalltag"
                            defaultMessage="(No. of Temporary Accounts: 0)"
                          />
                        </small>
                        <h4>
                          <FormattedMessage
                            id="accounts.Live"
                            defaultMessage="Live Account"
                          />
                        </h4>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Col>
              <Col className="mb-4">
                <div className="card dashboard-class-card mb-4">
                  <div className="card-body">
                    <h5 className="card-title border-none">
                      <FormattedMessage
                        id="menu.classes.allClass"
                        defaultMessage="All Classes"
                      />
                    </h5>
                    <div className="card-box" style={{ height: "150px" }}>
                      <NewClass />
                    </div>
                    <div className="mt-3 text-right">
                      <Link to="/allClass" className="read-more">
                        <FormattedMessage
                          id="dashboard.readMore"
                          defaultMessage="Read More"
                        />
                        <i className="zmdi zmdi-long-arrow-right ml-1"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <NewGift />
              </Col>
            </Row>
            <div>
              <GemsAdvertisementsMarquee />
            </div>
          </section>
        </div>
        <Announcements notice={true} lastPage="Dashboard" />
      </div>
    </>
  );
};

export default Dashboard;
